﻿@import "../../../Content/bootstrap/functions";
@import "../../../Content/bootstrap/variables";
@import "../../../Content/bootstrap/mixins";

/* Rudimentry fixes to get IE9 functional, adapted from https://github.com/coliff/bootstrap-ie8 */
.card-group .card, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12,
.col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12,
.breadcrumb .breadcrumb-item, .pagination page-item {
    float: left;
}

.row,
.container-fluid,
.container,
section,
footer,
main,
aside {
    @include clearfix();
    clear: both;
    display: block;
    width: 100%;
    height: 100%;
}

.navbar-nav,
.navbar-collapse,
.breadcrumb,
.pagination,
.form-inline {
    display: inline-block;
}

@include media-breakpoint-up(xl) {
    .navbar-expand-xl .navbar-nav {
        float: left;
        width: auto;

        .nav-item {
            float: left;
        }
    }
}

.modal.fade .modal-dialog {
    -ms-transform: translate(0, -25%)
}

.modal.show .modal-dialog {
    -ms-transform: translate(0, 0)
}

.custom-select {
    background-image: none;
    padding-right: .75rem;
}

.input-group {
    overflow: auto;
}

.input-group > :not(:last-child).form-control,
.input-group > :not(:last-child).custom-select {
    float: left;
    width: 80%;
}

.input-group-append {
    float: left;
}

.table-responsive {
    min-height: 0%;
}

/* see https://github.com/twbs/bootstrap/issues/14837 */
.progress {
    display: block;
}

.progress-bar {
    float: left;
    font-size: 12px;
    line-height: 20px;
    width: 0;
    text-align: center;
    height: 100%;
}

.collapse {
    display: inline-block;
}

.d-flex {
    display: block;
}

.d-inline-flex {
    display: inline-block;
}

.flex-row .div {
    display: inline-block;
}

.flex-row-reverse {
    text-align: right
}

.flex-row-reverse .div {
    display: inline-block;
    float: right;
}

.justify-content-start div {
    display: inline-block;
}

.justify-content-end {
    text-align: right
}

.justify-content-end div {
    display: inline-block;
    margin-right: 0;
    float: right;
}

.justify-content-center {
    text-align: center;
}

.justify-content-center div {
    display: inline-block;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}

.justify-content-between {
    text-justify: distribute-all-lines
}

.justify-content-between div {
    display: inline-block;
    margin-right: auto;
    margin-left: auto;
    text-align: justify;
}

.justify-content-around {
    text-align: justify;
    text-justify: distribute-all-lines;
}
/* see https://stackoverflow.com/questions/6865194/fluid-width-with-equally-spaced-divs */
.justify-content-around div {
    display: inline-block;
    margin-right: auto;
    margin-left: auto;
    text-align: justify;
}

[class^="justify-content-"] div {
    display: inline-block;
}

/** Carousel - Hide indicators and controls as the carousel doesn't work **/
.carousel-indicators,
.carousel-control-prev,
.carousel-control-next {
    display: none;
}
